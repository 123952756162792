import React from "react";

const Header = () => {
    return (
        <header className="header">
            <div className="header-title"></div>
            <button className="logout-button">Esci</button>
        </header>
    );
};

export default Header;