import {api} from "src/service/conf/axiosConf";

const authService = {

    login: async function login(username: string, password: string): Promise<any> {
        const form = new FormData();
        form.append('username', username)
        form.append('password', password)
        const response = await api.post("/auth/login", form);
        const token = response.data.access_token;
        authService.manageToken(token);
        return token;
    },

    refreshToken: async () => {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await api.post('/auth/token_refresh');
        return response.data;
    },

    manageToken: (token: string) => {
        localStorage.setItem('token', token);
    }
}

export default authService;
