import React, {useState} from 'react';

export const HomeComponent = () => {
    const [hoverText, setHoverText] = useState("");
    const [showFirstButtonCard, setShowFirstButtonCard] = useState(false);

    const chronografDescription = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tincidunt tincidunt commodo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi mauris ligula, iaculis ac magna quis, viverra scelerisque leo. Morbi ac metus a ipsum efficitur pellentesque. Fusce id lorem sit amet nisi consequat commodo eget id enim. Quisque leo felis, laoreet non sagittis vitae, tristique id lectus. Integer consequat ligula fringilla quam suscipit euismod. Etiam eget lacus libero. Mauris ut blandit orci. Sed a sodales elit, vitae ornare orci. Mauris eget pellentesque ante, eget fringilla ipsum. Cras sed velit ornare, porta ex eget, suscipit dui. Nunc interdum, lacus quis dictum fermentum, turpis nulla scelerisque ex, condimentum porta dui tellus ac velit.";

    const onClick = () => {
        window.location.replace('http://localhost:8888');
    }

    return (
        <>
            {showFirstButtonCard && ( // Condizione per mostrare o nascondere il primo button-card-body
                <div className="button-card-body">
                    <button className="card-button">
                        Gestione Utenti
                    </button>
                    <button type={"button"} onClick={onClick} className="card-button">
                        Chronograf
                    </button>
                </div>
            )}
            <div className="button-card-body">
                <button className="card-button">
                    Chronograf
                </button>
                <text>
                    {chronografDescription}
                </text>
                <div className="chronograf-logo"></div>
            </div>
        </>
    );
};
