import React from 'react';
import {useNavigate} from 'react-router-dom';
import Footer from "src/shared/footer/Footer";
import Header from "src/shared/header/Header";
import {HomeComponent} from "src/page/home/HomeComponent";

const Home = () => {
    const navigate = useNavigate();

    const onButtonClick = () => {
    };

    const username = "Tommaso";

    return (
        <div className="mainContainerHome">
            <div className="auth-image"/>
            <Header/>
            <div className="contentContainer">
                <div className={'titleContainer'}>
                    <div>Bentornato {username}!</div>
                </div>
                <HomeComponent/>
            </div>
            {/* Inserisci il Footer qui */}
            <Footer/>
        </div>
    );
};

export default Home;
