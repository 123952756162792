import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAuth} from 'src/hook/AuthProvider';

const PrivateRoutes = () => {
    const { isAuthenticated } = useAuth();

    return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
