import React, {useState} from 'react';
import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Home from "src/page/home/Home";
import Login from "src/page/login/Login";
import UserManager from "src/page/user/UserManager";
import PrivateRoutes from "src/shared/PrivateRoutes";
import {AuthProvider} from "src/hook/AuthProvider";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <AuthProvider>
                    <Routes>
                        <Route element={<PrivateRoutes />}>
                            <Route path="/home" element={<Home />} />
                            <Route path="/users" element={<UserManager />} />
                        </Route>
                        <Route path="/" element={<Navigate to="/login" />} />
                        <Route path="/login" element={<Login />} />
                    </Routes>
                </AuthProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
