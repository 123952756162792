import axios from "axios";
import {Config} from "src/config";

export const BASE_URL = Config.apiUrl;

export const api = axios.create({
    withCredentials: true,
    baseURL: BASE_URL
})

const errorHandler = (error: any) => {
    const statusCode = error.response?.status;

    if (statusCode && statusCode !== 401) {
        console.error('Error: ', error)
    }

    return Promise.reject(error)
}

api.interceptors.request.use(
    config => {
        config.headers["Authorization"] = "Bearer " + Config.token;
        config.headers["Access-Control-Allow-Origin"] = "*";
        config.headers["Access-Control-Allow-Headers"] = "*";
        config.headers["Access-Control-Allow-Methods"] = "*";
        return config
    },
    error => {
        Promise.reject(error).then();
    }
);

api.interceptors.response.use(
    undefined,
    (error) => errorHandler(error)
);
